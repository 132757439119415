<template>
  <div class="download-map">
    <a-table tid="0" :rowClassName="currentMapRowClassName" :size="tableSize" :columns="downloadColumns" :data-source="downloadList" rowKey="id" :pagination="false">
      <div slot="action" slot-scope="text, record">
        <template v-if="record.downloadStatus === 1">
          <a @click="handlePauseDownload(record)"><a-icon type="pause-circle" />暂停 </a>
          <a-divider type="vertical" />
          <a @click="handleCancelDownload(record)"> <a-icon type="close-circle" />删除 </a>
        </template>
        <template v-if="record.downloadStatus === 2">
          <a @click="handleContinueDownload(record)"> <a-icon type="play-circle" />继续下载 </a>
          <a-divider type="vertical" />
          <a @click="handleDeleteDownload(record)"> <a-icon type="delete" />删除 </a>
        </template>
        <template v-if="record.downloadStatus === 3">
          <a @click="handleChangeMap(record)" v-if="record.mapCurrent === 0">切换地图 </a>
          <a-divider type="vertical" v-if="record.mapCurrent === 0" />
          <a @click="handleDeleteDownload(record)"> <a-icon type="delete" />删除 </a>
        </template>
      </div>

      <template slot="title">
        <span class="title">车端仓库</span>
      </template>
    </a-table>

    <a-table :rowClassName="rowClassName" :loading="mapListLoading" :size="tableSize" :columns="columns" :data-source="mapList" rowKey="id" :pagination="false">
      <div slot="action" slot-scope="text, record" v-if="!record.mapVehicleId">
        <a @click="downloadMap(record)"> <a-icon type="cloud-download" />下载到车端 </a>
      </div>

      <template slot="title">
        <div class="cloud-map">
          <span class="title">云端仓库</span>
        </div>
        <!-- 条件搜索 -->
        <div class="table-page-search-wrapper">
          <a-form layout="inline">
            <a-row :gutter="48">
              <a-col :md="8" :sm="24">
                <a-form-item label="mapId" prop="vehicleId">
                  <a-input v-model="queryParam.mapId" placeholder="请输入地图mapId" allow-clear />
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="mapVersion" prop="vehicleId">
                  <a-input v-model="queryParam.mapVersion" placeholder="请输入地图mapVersion" allow-clear />
                </a-form-item>
              </a-col>

              <a-col :md="(!advanced && 8) || 24" :sm="24">
                <span class="table-page-search-submitButtons" :style="(advanced && { float: 'right', overflow: 'hidden' }) || {}">
                  <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
                  <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
                </span>
              </a-col>
            </a-row>
          </a-form>
        </div>
      </template>
    </a-table>

    <!-- 分页 -->
    <a-pagination
      class="ant-table-pagination"
      show-size-changer
      show-quick-jumper
      :current="queryParam.pageNum"
      :total="total"
      :page-size="queryParam.pageSize"
      :showTotal="(total) => `共 ${total} 条`"
      @showSizeChange="onShowSizeChange"
      @change="changeSize"
    />
  </div>
</template>

<script>
import TaskBase from './TaskBase.js'
import { mapState } from 'vuex'
import { MsgAnswer } from '@/utils/util.js'
import {
  listMap,
  listMapVehicle,
  downloadStart,
  downloadStartAck,
  downloadInt,
  downloadIntAck,
  mapDelete,
  mapDeleteAck,
  downloadGoOn,
  syncGitMap,
  syncVehicleMap,
  syncVehicleMapAck,
  mapChange,
  mapChangeAck
} from '@/api/iot/map'
const columns = [
  {
    title: 'mapId',
    key: 'mapId',
    dataIndex: 'mapId',
    ellipsis: true
  },
  {
    title: 'mapVersion',
    key: 'mapVersion',
    dataIndex: 'mapVersion',
    ellipsis: true
  },
  {
    title: '地图描述',
    key: 'name',
    dataIndex: 'name',
    ellipsis: false,
    width: 250
  },
  {
    title: '文件大小',
    key: 'mapSize',
    dataIndex: 'mapSize',
    customRender: (text, record, index) => {
      const val = Number(record.mapSize) / 1024 / 1024
      return val > 0 ? val.toFixed(2) + 'M' : '-'
    },
    ellipsis: true,
    width: 100
  },
  {
    title: '创建时间',
    key: 'createTime',
    dataIndex: 'createTime',
    ellipsis: true
  },
  // {
  //   title: '传输状态',
  //   key: 'downloadStatus',
  //   customRender: (text, record, index) => {
  //     const downloadStatus = record.downloadStatus
  //     if (downloadStatus === 1) {
  //       return '下载中' + record.progress + '%'
  //     } else if (downloadStatus === 2) {
  //       return '下载被暂停'
  //     } else if (downloadStatus === 3) {
  //       return '下载完成'
  //     }
  //   },
  //   ellipsis: true
  // },

  {
    title: '操作',
    key: 'action',
    scopedSlots: { customRender: 'action' },
    width: 220
  }
]

const downloadColumns = [
  {
    title: 'mapId',
    key: 'mapId',
    dataIndex: 'mapId',
    ellipsis: true
  },
  {
    title: 'mapVersion',
    key: 'mapVersion',
    dataIndex: 'mapVersion',
    ellipsis: true
  },
  {
    title: '地图描述',
    key: 'name',
    dataIndex: 'name',
    ellipsis: false,
    width: 300
  },
  {
    title: '文件大小',
    key: 'mapSize',
    dataIndex: 'mapSize',
    customRender: (text, record, index) => {
      const val = Number(record.mapSize) / 1024 / 1024
      return val > 0 ? val.toFixed(2) + 'M' : '-'
    },
    ellipsis: true,
    width: 100
  },
  {
    title: '下载完成时间',
    key: 'downloadTime',
    dataIndex: 'downloadTime',
    ellipsis: true,
    width: 150
  },

  {
    title: '传输状态',
    key: 'downloadStatus',
    customRender: (text, record, index) => {
      const downloadStatus = record.downloadStatus
      if (downloadStatus === 1) {
        return '下载中' + record.progress + '%'
      } else if (downloadStatus === 2) {
        return '下载被暂停' + record.progress + '%'
      } else if (downloadStatus === 3) {
        return '下载完成'
      }
    },
    ellipsis: true
  },
  {
    title: '当前地图',
    key: 'mapCurrent',
    dataIndex: 'mapCurrent',
    customRender: (text, record, index) => {
      const mapCurrent = record.mapCurrent
      if (mapCurrent === 1) {
        return '是'
      } else {
        return '否'
      }
    },
    ellipsis: true
  },
  {
    title: '操作',
    key: 'action',
    scopedSlots: { customRender: 'action' },
    width: 220
  }
]

export default {
  mixins: [TaskBase],
  name: 'ViewTaskModal',
  props: {
    info: {
      type: Object,
      default: () => {}
    },
    position: {
      type: Object,
      default: () => {}
    },
    isShowModal: {
      type: Boolean,
      default: () => {}
    },
    vehicleId: {
      type: String,
      default: () => {}
    },
    params: {
      type: Object,
      default: () => {}
    },
    tabKey: {
      type: String,
      default: () => {}
    }
  },
  data() {
    // 节流控制
    return {
      columns,
      downloadColumns,
      // 高级搜索 展开/关闭
      advanced: false,
      loading: false,
      mapListLoading: false,
      // 查询参数
      queryParam: {
        vehicleId: this.vehicleId,
        mapId: '',
        mapVersion: '',
        pageNum: 1,
        pageSize: 10
      },
      mapList: [],
      total: 0,
      downloadList: [],
      open: false,
      infoTitle: '',
      tips: '',
      spinning: false,
      vehicleRemoteCtlResultOptions: []
    }
  },
  computed: {
    ...mapState({
      ackData: (state) => state.websocket.ackData
    })
  },
  watch: {
    tabKey(newVal, oldVal) {
      if (newVal === 0) {
        this.initMapTimer()
      } else {
        this.mapTimer && clearInterval(this.mapTimer)
        this.gitMapTimer && clearInterval(this.gitMapTimer)
      }
    }
  },
  created() {
    this.getData()
  },
  methods: {
    handleChangeMap(record) {
      if (record.mapCurrent === 1) {
        return
      }
      MsgAnswer('是否切换车端地图?').then(() => {
        const params = {
          vehicleId: this.vehicleId,
          mapId: record.mapId,
          mapVersion: record.mapVersion
        }
        this.$loading.show({
          msg: '正在切换车端地图'
        })
        mapChange(params)
          .then((response) => {
            this.isClearTimeout = false
            this.taskAckBeginTickount = new Date()
            const params = {
              vehicleId: this.vehicleId
            }
            this.getTaskAck(mapChangeAck, params, 'mapChange')
          })
          .catch((e) => {
            console.log(e)
            this.$loading.hide()
          })
      })
    },
    handleSyncVehicleMap() {
      const params = this.getFormData('vehicleId', this.vehicleId)
      this.$loading.show({
        msg: '正在同步车端地图'
      })
      syncVehicleMap(params)
        .then((response) => {
          this.isClearTimeout = false
          this.taskAckBeginTickount = new Date()
          const params = {
            vehicleId: this.vehicleId
          }
          this.getTaskAck(syncVehicleMapAck, params, 'mapSync')
        })
        .catch((e) => {
          console.log(e)
          this.$loading.hide()
        })
    },
    handleSyncGitMap() {
      this.$emit('onLoading', true)
      syncGitMap({}).then((response) => {
        this.$emit('onLoading', false)
        this.$successEx('同步成功')
      })
    },
    toEnd(infoType, result) {
      this.$loading.hide()
      let myModal = this.$successEx
      if (infoType === 'warn') {
        myModal = this.$warningEx
      } else if (infoType === 'error') {
        myModal = this.$errorEx
      }
      myModal(result, () => {
        this.clearTimeoutEx()
        this.getDownloadList()
      })
    },
    async getData() {
      // 车端回传结果状态
      await this.getDicts('iot_vehicle_remoteCtlResult').then((response) => {
        this.vehicleRemoteCtlResultOptions = response.data
      })
      this.getList()
      this.initMapTimer()
    },
    initMapTimer() {
      this.mapTimer && clearInterval(this.mapTimer)
      this.gitMapTimer && clearInterval(this.gitMapTimer)
      this.mapTimer = setInterval(() => {
        this.getDownloadList()
      }, 5000)
      this.gitMapTimer = setInterval(() => {
        this.getMapList()
      }, 15000)
    },
    rowClassName(record, index) {
      console.log('rowClassName')
      let className = ''
      if (record.downloadStatus === 3) {
        className = 'download-finish'
      }
      return className
    },
    currentMapRowClassName(record, index) {
      console.log('currentMapRowClassName')
      let className = ''
      if (record.mapCurrent === 1) {
        className = 'download-finish'
      }
      return className
    },
    handleCancelDownload(record) {
      this.handleDeleteDownload(record)
    },
    handlePauseDownload(record) {
      MsgAnswer('是否暂停下载地图?')
        .then(() => {
          const params = this.getFormData('mapVehicleId', record.mapVehicleId)
          this.$loading.show({
            msg: '正在暂停下载地图'
          })
          downloadInt(params).then((response) => {
            this.isClearTimeout = false
            this.taskAckBeginTickount = new Date()
            const params = {
              vehicleId: this.vehicleId
            }
            this.getTaskAck(downloadIntAck, params, 'mapInt')
          })
        })
        .catch((e) => {
          console.log(e)
          this.$loading.hide()
        })
    },
    handleContinueDownload(record) {
      const params = this.getFormData('mapVehicleId', record.mapVehicleId)
      this.$loading.show({
        msg: '正在继续下载地图'
      })
      downloadGoOn(params)
        .then((response) => {
          this.isClearTimeout = false
          this.taskAckBeginTickount = new Date()
          const params = {
            vehicleId: this.vehicleId
          }
          this.getTaskAck(downloadStartAck, params, 'mapDowlodStart')
        })
        .catch((e) => {
          console.log(e)
          this.$loading.hide()
        })
    },
    getFormData(key, value) {
      const formData = new FormData()
      formData.append(key, value)
      return formData
    },
    handleDeleteDownload(record) {
      MsgAnswer('是否删除地图?', 2).then(() => {
        const params = this.getFormData('mapVehicleId', record.mapVehicleId)
        this.$loading.show({
          msg: '正在删除地图'
        })
        mapDelete(params)
          .then((response) => {
            this.isClearTimeout = false
            this.taskAckBeginTickount = new Date()
            const params = {
              vehicleId: this.vehicleId
            }
            this.getTaskAck(mapDeleteAck, params, 'mapDel')
          })
          .catch((e) => {
            console.log(e)
            this.$loading.hide()
          })
      })
    },
    downloadMap(record) {
      const queryParam = {
        vehicleId: this.vehicleId,
        raMapId: record.id
      }
      this.$loading.show({
        msg: '开始下载地图'
      })
      downloadStart(queryParam)
        .then((response) => {
          this.isClearTimeout = false
          this.taskAckBeginTickount = new Date()
          const params = {
            vehicleId: this.vehicleId
          }
          this.getTaskAck(downloadStartAck, params, 'mapDowlodStart')
        })
        .catch((e) => {
          console.log(e)
          this.$loading.hide()
        })
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.getMapList()

      //   this.getList()
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.daterangeHandleTime = []
      this.queryParam = {
        vehicleId: this.vehicleId,
        mapId: '',
        mapVersion: '',
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    toggleAdvanced() {
      this.advanced = !this.advanced
    },
    getMapList() {
      listMap(this.queryParam).then((response) => {
        this.mapList = response.rows
        this.total = response.total
      })
    },
    getList() {
      this.getMapList()
      this.getDownloadList()
    },
    getDownloadList(queryParam) {
      const params = {
        vehicleId: this.vehicleId
      }
      listMapVehicle(params).then((res) => {
        this.downloadList = res.data
        const isDownloading = res.data.filter((p) => p.downloadStatus === 1).length > 0
        console.log('isDownloading', isDownloading)
        this.$emit('onDownloading', isDownloading)
      })
    },
    toViewRoute(record) {
      console.log('toViewRoute')
      this.$emit('viewRoute', record.taskRouteId)
    },
    onShowSizeChange(current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getMapList()
    },
    changeSize(current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getMapList()
    },
    handleNavigateTo(record) {
      this.$emit('navigateTo', 'taskRecord', {
        taskId: record.id,
        breadcrumb: false,
        parentTitle: this.vehicleId + '-' + record.taskName + '-'
      })
    }
  },
  beforeDestroy() {
    console.log('dowloadmap', 'beforeDestroy')
    this.isClearTimeout = true
    this.mapTimer && clearInterval(this.mapTimer)
    this.gitMapTimer && clearInterval(this.gitMapTimer)
    this.changeMapTimer && clearInterval(this.changeMapTimer)
    this.syncVehicleMapTimer && clearInterval(this.syncVehicleMapTimer)
    this.changeMapTimer && clearInterval(this.changeMapTimer)
    this.downloadStartTimer && clearInterval(this.downloadStartTimer)
    this.downloadIntTimer && clearInterval(this.downloadIntTimer)
    this.mapDeleteTimer && clearInterval(this.mapDeleteTimer)
    this.clearTimeoutEx()
  }
}
</script>
<style lang="less">
.download-map {
  .title {
    font-size: 16px;
    font-weight: 800;
    margin-right: 20px;
    color: #272727;
  }
  .cloud-map {
    padding-top: 15px;
    padding-bottom: 20px;
  }
  .download-finish {
    background: #e6f7ff;
  }
}
</style>
