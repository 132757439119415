var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "download-map" },
    [
      _c(
        "a-table",
        {
          attrs: {
            tid: "0",
            rowClassName: _vm.currentMapRowClassName,
            size: _vm.tableSize,
            columns: _vm.downloadColumns,
            "data-source": _vm.downloadList,
            rowKey: "id",
            pagination: false,
          },
          scopedSlots: _vm._u([
            {
              key: "action",
              fn: function (text, record) {
                return _c(
                  "div",
                  {},
                  [
                    record.downloadStatus === 1
                      ? [
                          _c(
                            "a",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.handlePauseDownload(record)
                                },
                              },
                            },
                            [
                              _c("a-icon", { attrs: { type: "pause-circle" } }),
                              _vm._v("暂停 "),
                            ],
                            1
                          ),
                          _c("a-divider", { attrs: { type: "vertical" } }),
                          _c(
                            "a",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.handleCancelDownload(record)
                                },
                              },
                            },
                            [
                              _c("a-icon", { attrs: { type: "close-circle" } }),
                              _vm._v("删除 "),
                            ],
                            1
                          ),
                        ]
                      : _vm._e(),
                    record.downloadStatus === 2
                      ? [
                          _c(
                            "a",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.handleContinueDownload(record)
                                },
                              },
                            },
                            [
                              _c("a-icon", { attrs: { type: "play-circle" } }),
                              _vm._v("继续下载 "),
                            ],
                            1
                          ),
                          _c("a-divider", { attrs: { type: "vertical" } }),
                          _c(
                            "a",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.handleDeleteDownload(record)
                                },
                              },
                            },
                            [
                              _c("a-icon", { attrs: { type: "delete" } }),
                              _vm._v("删除 "),
                            ],
                            1
                          ),
                        ]
                      : _vm._e(),
                    record.downloadStatus === 3
                      ? [
                          record.mapCurrent === 0
                            ? _c(
                                "a",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleChangeMap(record)
                                    },
                                  },
                                },
                                [_vm._v("切换地图 ")]
                              )
                            : _vm._e(),
                          record.mapCurrent === 0
                            ? _c("a-divider", { attrs: { type: "vertical" } })
                            : _vm._e(),
                          _c(
                            "a",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.handleDeleteDownload(record)
                                },
                              },
                            },
                            [
                              _c("a-icon", { attrs: { type: "delete" } }),
                              _vm._v("删除 "),
                            ],
                            1
                          ),
                        ]
                      : _vm._e(),
                  ],
                  2
                )
              },
            },
          ]),
        },
        [
          _c("template", { slot: "title" }, [
            _c("span", { staticClass: "title" }, [_vm._v("车端仓库")]),
          ]),
        ],
        2
      ),
      _c(
        "a-table",
        {
          attrs: {
            rowClassName: _vm.rowClassName,
            loading: _vm.mapListLoading,
            size: _vm.tableSize,
            columns: _vm.columns,
            "data-source": _vm.mapList,
            rowKey: "id",
            pagination: false,
          },
          scopedSlots: _vm._u(
            [
              {
                key: "action",
                fn: function (text, record) {
                  return !record.mapVehicleId
                    ? _c("div", {}, [
                        _c(
                          "a",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.downloadMap(record)
                              },
                            },
                          },
                          [
                            _c("a-icon", { attrs: { type: "cloud-download" } }),
                            _vm._v("下载到车端 "),
                          ],
                          1
                        ),
                      ])
                    : _vm._e()
                },
              },
            ],
            null,
            true
          ),
        },
        [
          _c("template", { slot: "title" }, [
            _c("div", { staticClass: "cloud-map" }, [
              _c("span", { staticClass: "title" }, [_vm._v("云端仓库")]),
            ]),
            _c(
              "div",
              { staticClass: "table-page-search-wrapper" },
              [
                _c(
                  "a-form",
                  { attrs: { layout: "inline" } },
                  [
                    _c(
                      "a-row",
                      { attrs: { gutter: 48 } },
                      [
                        _c(
                          "a-col",
                          { attrs: { md: 8, sm: 24 } },
                          [
                            _c(
                              "a-form-item",
                              { attrs: { label: "mapId", prop: "vehicleId" } },
                              [
                                _c("a-input", {
                                  attrs: {
                                    placeholder: "请输入地图mapId",
                                    "allow-clear": "",
                                  },
                                  model: {
                                    value: _vm.queryParam.mapId,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.queryParam, "mapId", $$v)
                                    },
                                    expression: "queryParam.mapId",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "a-col",
                          { attrs: { md: 8, sm: 24 } },
                          [
                            _c(
                              "a-form-item",
                              {
                                attrs: {
                                  label: "mapVersion",
                                  prop: "vehicleId",
                                },
                              },
                              [
                                _c("a-input", {
                                  attrs: {
                                    placeholder: "请输入地图mapVersion",
                                    "allow-clear": "",
                                  },
                                  model: {
                                    value: _vm.queryParam.mapVersion,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.queryParam,
                                        "mapVersion",
                                        $$v
                                      )
                                    },
                                    expression: "queryParam.mapVersion",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "a-col",
                          { attrs: { md: (!_vm.advanced && 8) || 24, sm: 24 } },
                          [
                            _c(
                              "span",
                              {
                                staticClass: "table-page-search-submitButtons",
                                style:
                                  (_vm.advanced && {
                                    float: "right",
                                    overflow: "hidden",
                                  }) ||
                                  {},
                              },
                              [
                                _c(
                                  "a-button",
                                  {
                                    attrs: { type: "primary" },
                                    on: { click: _vm.handleQuery },
                                  },
                                  [
                                    _c("a-icon", { attrs: { type: "search" } }),
                                    _vm._v("查询"),
                                  ],
                                  1
                                ),
                                _c(
                                  "a-button",
                                  {
                                    staticStyle: { "margin-left": "8px" },
                                    on: { click: _vm.resetQuery },
                                  },
                                  [
                                    _c("a-icon", { attrs: { type: "redo" } }),
                                    _vm._v("重置"),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _c("a-pagination", {
        staticClass: "ant-table-pagination",
        attrs: {
          "show-size-changer": "",
          "show-quick-jumper": "",
          current: _vm.queryParam.pageNum,
          total: _vm.total,
          "page-size": _vm.queryParam.pageSize,
          showTotal: function (total) {
            return "共 " + total + " 条"
          },
        },
        on: { showSizeChange: _vm.onShowSizeChange, change: _vm.changeSize },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }